/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import ProductGrid from "~components/ProductGrid";
import SEO from "~components/SEO";
import { parseProducts } from "~utils/shopify";

const CollectionPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const { frontmatter } = data.markdownRemark;

  const products = parseProducts(data);

  const collectionItems = [];

  products.forEach(product => {
    if (
      product?.frontmatter?.collectionHandle.toLowerCase() ===
      frontmatter.collectionHandle.toLowerCase()
    ) {
      collectionItems.push(product);
    }
  });

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="collection-page w-full relative">
        <section className="w-full relative block pt-20 xs:pt-12">
          <div className="grid pt-20">
            <article className="grid-end-6 sm:grid-end-9 xs:grid-end-12">
              <h1
                className={`animation-appear-down-slow animation-delay-1 ${
                  device === `desktop` ? `f1` : `f2`
                }`}
              >
                {frontmatter.title}
              </h1>

              <p className="animation-appear-down-slow animation-delay-2 mt-6 b1">
                {frontmatter.intro}
              </p>
            </article>
          </div>
        </section>

        <ProductGrid
          className="animation-appear-slow animation-delay-5"
          compact
          noborder
          products={collectionItems}
        />
      </Layout>

      <Footer />
    </>
  );
};

export default CollectionPage;

export const query = graphql`
  query CollectionPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        collection
        collectionHandle
        intro
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "product-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            shopifyHandle
            priority
            collection
            collectionHandle
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          id
          title
          description
          handle
          images {
            originalSrc
          }
          productType
          vendor
          variants {
            id
            title
            image {
              originalSrc
            }
            price
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
    allShopifyAdminProduct {
      edges {
        node {
          products {
            handle
            variants {
              alternative_id
            }
          }
        }
      }
    }
  }
`;
